import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Card,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const CustomerSignup = () => {
  const classes = useStyles();

  // Form state
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    name: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    zip: "",
  });

  // Coordinate state (not part of visible form)
  const [coordinates, setCoordinates] = useState("");

  // Other states
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Router hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { admin } = useParams();

  // Extract session_id from query params
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");

  useEffect(() => {
    if (!sessionId) {
      setErrorMessage(
        "No payment session found. Please complete the payment first."
      );
    }
  }, [sessionId]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // Geocoding API call to convert address to coordinates
  const getCoordinates = async () => {
    const { street, city, state, zip } = formData;
    const address = `${street}, ${city}, ${state}, ${zip}`;
    const KEY = process.env.REACT_APP_GEO_API_KEY;

    if (!KEY) {
      setErrorMessage("API key is not defined. Please check your .env file.");
      return;
    }
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          address
        )}&key=${KEY}`
      );

      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry;
        setCoordinates(`${lat},${lng}`);
      } else {
        setErrorMessage("No coordinates found for the given address.");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      setErrorMessage("Unable to get coordinates. Please check the address.");
    }
  };

  // Handle form submission
  const handleSignup = async (e) => {
    e.preventDefault();

    setSuccessMessage("");
    setErrorMessage("");

    // Validate form
    if (!validate()) return;

    setLoading(true);

    // Get coordinates from address
    await getCoordinates();

    try {
      await axios.post("https://api.skilledcrm.com/customer-signup/", {
        ...formData,
        coordinates,  // Add coordinates to payload
        session_id: sessionId,
        admin_id: admin,
      });

      setSuccessMessage("Signup successful!");
      setLoading(false);

      setTimeout(() => navigate("/login"), 2000);
    } catch (error) {
      setLoading(false);
      console.error("Error during signup:", error);
      setErrorMessage("Error during signup. Please try again.");
    }
  };

  // Form validation
  const validate = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.username) newErrors.username = "Username is required.";
    if (!formData.phone) newErrors.phone = "Phone number is required.";
    if (!formData.street) newErrors.street = "Street address is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.zip) newErrors.zip = "Zip code is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Container component="main" maxWidth="md">
      <Card className={classes.container}>
        <Typography component="h1" variant="h5" align="center" gutterBottom>
          Customer Signup
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* Display Success Message */}
        {successMessage && (
          <Alert
            severity="success"
            onClose={() => setSuccessMessage("")}
            sx={{ mt: 2 }}
          >
            {successMessage}
          </Alert>
        )}

        {/* Display Error Message */}
        {errorMessage && (
          <Alert
            severity="error"
            onClose={() => setErrorMessage("")}
            sx={{ mt: 2 }}
          >
            {errorMessage}
          </Alert>
        )}

        <Box
          component="form"
          noValidate
          onSubmit={handleSignup}
          className={classes.form}
        >
          <Grid container spacing={2}>
            {/* Name and Username */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                error={Boolean(errors.username)}
                helperText={errors.username}
              />
            </Grid>

            {/* Email and Phone */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="phone"
                label="Phone"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleChange}
                error={Boolean(errors.phone)}
                helperText={errors.phone}
              />
            </Grid>

            {/* Password */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
              />
            </Grid>

            {/* Address Fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="street"
                label="Street Address"
                name="street"
                value={formData.street}
                onChange={handleChange}
                error={Boolean(errors.street)}
                helperText={errors.street}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="city"
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                error={Boolean(errors.city)}
                helperText={errors.city}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="state"
                label="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
                error={Boolean(errors.state)}
                helperText={errors.state}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="zip"
                label="Zip Code"
                name="zip"
                value={formData.zip}
                onChange={handleChange}
                error={Boolean(errors.zip)}
                helperText={errors.zip}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading || !sessionId}
            startIcon={
              loading && <CircularProgress size={20} color="inherit" />
            }
          >
            {loading ? "Signing Up..." : "Sign Up"}
          </Button>
        </Box>
      </Card>
    </Container>
  );
};

export default CustomerSignup;
