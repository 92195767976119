// src/App.js

import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import Page from "./components/Page";
import Service from './views/Service/Service';
import ContactUs from './views/ContactPage';
import Pricing from './views/Products';
import CustomerSignup from "./views/CustomerSignUp";
import CustomerDashboard from "./views/CustomerDashboard";
import AdminDashboard from "./views/AdminDashboard";
import Payment from "./views/Payment";
import Login from "./views/Login";

function App() {
  return (
      <Router>
        <Page>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Service />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/products" element={<Pricing />} />
            {/*<Route path="/signup" element={<SignUp />} />*/}

            <Route path="/admin-dashboard" element={<AdminDashboard />} />
              <Route path="/customer-signup/:admin" element={<CustomerSignup />} />
              <Route path={'payment/:usr'} element={<Payment/>} />
              <Route path="/customer-dashboard" element={<CustomerDashboard />} />
            <Route path={"/login"} element={<Login/>} />
              {/*<Route path="/login" element={<Login />} />*/}

            {/* Catch-All Route: Redirect to Home if no match is found */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Page>
      </Router>
  );
}

export default App;
