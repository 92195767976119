import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import useMediaQuery from '@mui/material/useMediaQuery';

import Container from '../../../../components/Container';

const mock = [
  {
    title: 'Starter',
    subtitle: 'Is perfect for individual operators',
    price: '$500',
    features: ['Facebook ADs', 'Lead Generation', 'Sales'],
    isHighlighted: false,
  },
  {
    title: 'Pro',
    subtitle: 'For growing business looking to scale',
    price: '$1000',
    features: [
      'All in Starter plan',
      'Google Ads',
      'SEO via Google',
      'Customer Relation Management Software',
    ],
    isHighlighted: true,
  },
  {
    title: 'Enterprise',
    subtitle: 'Ideal for businesses that want to scale nationally',
    price: '$5000',
    features: [
      'All features',
      'Accounting Software',
      'Efficient Branding',
      'SEO via Google',
      'Hiring Employees',
      'Business Structuring',
    ],
    isHighlighted: false,
  },
];

const Main = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
      <Box>
        <Box
            sx={{
              position: 'relative',
              backgroundColor: theme.palette.alternate.main,
              backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
              marginTop: -13,
              paddingTop: 13,
            }}
        >
          <Container position={'relative'} zIndex={3}>
            <Box>
              <Box marginBottom={4}>
                <Typography
                    variant="h3"
                    gutterBottom
                    align={'center'}
                    sx={{
                      fontWeight: 900,
                    }}
                >
                  Flexible pricing options
                </Typography>
                <Typography
                    variant="h6"
                    component="p"
                    color="text.primary"
                    align={'center'}
                >
                  Skilled Strategies is founded by industry experts with a deep understanding of blue-collar business needs.
                  <br />
                  Whether you run a small business, are a service provider, or a contractor, our customized plans are designed to help you grow. If you’re looking for something specific, we’ve got the solution for you!
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
        <Container>
          <Grid container spacing={4}>
            {mock.map((item, i) => (
                <Grid item xs={12} md={4} key={i}>
                  <Box
                      component={Card}
                      height={1}
                      display={'flex'}
                      flexDirection={'column'}
                      variant={'outlined'}
                  >
                    <CardContent
                        sx={{
                          padding: 4,
                        }}
                    >
                      <Box marginBottom={2}>
                        <Typography variant={'h4'} fontWeight={600} gutterBottom>
                          {item.title}
                        </Typography>
                        <Typography color={'text.secondary'}>
                          {item.subtitle}
                        </Typography>
                      </Box>
                      <Box
                          display={'flex'}
                          alignItems={'baseline'}

                      >
                        <Typography variant={'h3'} fontWeight={700}>
                          {item.price}
                        </Typography>
                      </Box>
                      <Typography
                          variant={'subtitle1'}
                          color={'text.secondary'}
                          fontWeight={700}
                          marginBottom = {2}
                      >
                        Initial fee before quote
                      </Typography>
                      <Grid container spacing={1}>
                        {item.features.map((feature, j) => (
                            <Grid item xs={12} key={j}>
                              <Box
                                  component={ListItem}
                                  disableGutters
                                  width={'auto'}
                                  padding={0}
                              >
                                <Box
                                    component={ListItemAvatar}
                                    minWidth={'auto !important'}
                                    marginRight={2}
                                >
                                  <Box
                                      component={Avatar}
                                      bgcolor={theme.palette.primary.main}
                                      width={20}
                                      height={20}
                                  >
                                    <svg
                                        width={12}
                                        height={12}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                      <path
                                          fillRule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clipRule="evenodd"
                                      />
                                    </svg>
                                  </Box>
                                </Box>
                                <ListItemText primary={feature} />
                              </Box>
                            </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                    <Box flexGrow={1} />
                    <CardActions sx={{ justifyContent: 'flex-end', padding: 4 }}>
                      <Button size={'large'} href='/contact' variant={'contained'}>
                        Contact Us for Quote
                      </Button>
                    </CardActions>
                  </Box>
                </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
  );
};

export default Main;