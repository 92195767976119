import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

// Import Firestore
import { db } from '../../../../firebaseConfig'; // Ensure you import the initialized Firestore instance
import { collection, addDoc, doc } from "firebase/firestore";

const validationSchema = yup.object({
    firstName: yup
        .string()
        .trim()
        .min(2, 'Please enter a valid name')
        .max(50, 'Please enter a valid name')
        .required('Please specify your first name'),
    lastName: yup
        .string()
        .trim()
        .min(2, 'Please enter a valid name')
        .max(50, 'Please enter a valid name')
        .required('Please specify your last name'),
    phone: yup
        .string()
        .matches(/^[0-9]+$/, "Please enter a valid phone number")
        .min(10, 'Phone number should be at least 10 digits')
        .max(15, 'Phone number should be at most 15 digits')
        .required('Phone number is required.'),
    message: yup
        .string()
        .trim()
        .required('Please specify your message'),
});

const Contact = () => {
    const theme = useTheme();
    const [formSubmitted, setFormSubmitted] = useState(false); // New state for tracking form submission

    const initialValues = {
        firstName: '',
        lastName: '',
        phone: '',
        message: '',
    };

    // Function to handle form submission
    const onSubmit = async (values, { resetForm }) => {
        try {
            // Reference to the 'pending' document inside the 'messages' collection
            const pendingDocRef = doc(db, "messages", "pending");

            // Reference to the 'messages' subcollection inside the 'pending' document
            const messagesSubcollectionRef = collection(pendingDocRef, "messages");

            // Add a new document with a random ID to the 'messages' subcollection
            const docRef = await addDoc(messagesSubcollectionRef, {
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
                message: values.message,
                createdAt: new Date(),  // Optional: Add timestamp
            });

            console.log("Document written with ID: ", docRef.id);

            // Reset form after submission
            resetForm();

            // Set form submission state to true
            setFormSubmitted(true);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });

    // If the form is submitted, display a "Thank you" message
    if (formSubmitted) {
        return (
            <Box maxWidth={600} margin={'0 auto'} textAlign="center">
                <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
                    Thank you!
                </Typography>
                <Typography color="text.secondary">
                    We have received your message and will be in touch with you soon.
                </Typography>
            </Box>
        );
    }

    return (
        <Box maxWidth={600} margin={'0 auto'}>
            <Box marginBottom={4}>
                <Typography
                    variant={'h3'}
                    sx={{ fontWeight: 700 }}
                    align={'center'}
                    gutterBottom
                >
                    Contact us
                </Typography>
                <Typography color="text.secondary" align={'center'}>
                    Instead of struggling with finding new clients or managing operational challenges,
                    let Skilled Strategies help you scale. Whether you’re starting small or aiming to expand,
                    we’ll guide you from running a local business to managing a growing company with long-term success.
                </Typography>
            </Box>
            <Box>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ height: 54 }}
                                label="First name"
                                variant="outlined"
                                color="primary"
                                size="medium"
                                name="firstName"
                                fullWidth
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.firstName && Boolean(formik.errors.firstName)
                                }
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ height: 54 }}
                                label="Last name"
                                variant="outlined"
                                color="primary"
                                size="medium"
                                name="lastName"
                                fullWidth
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.lastName && Boolean(formik.errors.lastName)
                                }
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                sx={{ height: 54 }}
                                label="Phone number"
                                type="tel"
                                variant="outlined"
                                color="primary"
                                size="medium"
                                name="phone"
                                fullWidth
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="What problems are you looking to solve?"
                                multiline
                                rows={6}
                                variant="outlined"
                                color="primary"
                                size="medium"
                                name="message"
                                fullWidth
                                value={formik.values.message}
                                onChange={formik.handleChange}
                                error={formik.touched.message && Boolean(formik.errors.message)}
                                helperText={formik.touched.message && formik.errors.message}
                            />
                        </Grid>
                        <Grid item container justifyContent={'center'} xs={12}>
                            <Button
                                sx={{ height: 54, minWidth: 150 }}
                                variant="contained"
                                color="primary"
                                size="medium"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item container justifyContent={'center'} xs={12}>
                            <Typography color="text.secondary">
                                We'll get back to you in 1-2 business days.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item container justifyContent={'center'} xs={12}>
                            <Box>
                                <Typography component="p" variant="body2" align="left">
                                    By clicking on "submit" you agree to our{' '}
                                    <Box
                                        component="a"
                                        href=""
                                        color={theme.palette.text.primary}
                                        fontWeight={'700'}
                                    >
                                        Privacy Policy
                                    </Box>
                                    ,{' '}
                                    <Box
                                        component="a"
                                        href=""
                                        color={theme.palette.text.primary}
                                        fontWeight={'700'}
                                    >
                                        Data Policy
                                    </Box>{' '}
                                    and{' '}
                                    <Box
                                        component="a"
                                        href=""
                                        color={theme.palette.text.primary}
                                        fontWeight={'700'}
                                    >
                                        Cookie Policy
                                    </Box>
                                    .
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    );
};

export default Contact;