import React, { useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Card,
} from '@mui/material';

const Payment = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { usr } = useParams();

  const handlePayment = async () => {
    setLoading(true);

    try {
      // Call the backend to create a Stripe session
      const response = await axios.post('https://api.skilledcrm.com/customer-payment/', {
        user_id: usr,
        email
      });

      const sessionId = response.data.sessionId;

      // Redirect to Stripe Checkout
      //const stripe = await loadStripe('pk_live_51Q3RZsE2ODkBvnu2spjyoNa12v5JCCxWvRUpaJ8jMygyZXyiYnLkPS2Uwvz8l3pDZxqOvpevZmf4ZuBx9Daq1GtK00e8rgikAA');
      const stripe = await loadStripe('pk_test_51Q3RZsE2ODkBvnu2bfJRPC1Ux0oVGLQLFLHtHV1jBxO31tSs7i7USuGQeqg6vwgx1VISibpa3Ky4WmNMP8Ms3sUI00wgdeopZo')
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error during payment', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Card sx={{ p: 4, boxShadow: 3, borderRadius: 2 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Pay for the Service
        </Typography>

        <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
          <TextField
            fullWidth
            required
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 3 }}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handlePayment}
            disabled={loading || !email}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            {loading ? 'Processing...' : 'Pay with Stripe'}
          </Button>
        </Box>
      </Card>
    </Container>
  );
};

export default Payment;
