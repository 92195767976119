import { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton
} from "@mui/material";
import { Main } from "../layouts";
import { UserContext } from "../UserProvider";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const AdminDashboard = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stripeConnected, setStripeConnected] = useState(false);
  const { token } = useContext(UserContext);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get("https://api.skilledcrm.com/admin-dashboard/", {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        });
        setCustomers(response.data.customers);
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setLoading(false);
      }
    };

    const checkStripe = async () => {
      try {
        const response = await axios.get('https://api.skilledcrm.com/check-stripe/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setStripeConnected(response.data.connected);
      } catch (error) {
        console.error('Error fetching Stripe details:', error);
      }
    };

    fetchCustomers();
    checkStripe();
  }, [token]);

  // Redirect user to Stripe connection URL
  const handleConnectStripe = async () => {
    try {
      const response = await axios.post(
        'https://api.skilledcrm.com/admin-stripe-connect/',
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      // Redirect to the Stripe URL
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error fetching Stripe details:', error);
    }
  };

  let user = localStorage.getItem('user');
  const link = `cardinalbins.com/payment/${user}`;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link)
      .then(() => setCopySuccess(true))
      .catch((err) => console.error('Failed to copy: ', err));

    // Reset copy success after a delay
    setTimeout(() => setCopySuccess(false), 2000);
  };

  return (
    <Main>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Admin Dashboard
        </Typography>

        {/* Copy link to clipboard */}
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 4 }}>
          <TextField
            label="Payment Link"
            variant="outlined"
            value={link}
            InputProps={{ readOnly: true }}
            sx={{ width: '60%', mr: 2 }}
          />
          <IconButton
            color="primary"
            onClick={handleCopyLink}
            aria-label="copy to clipboard"
          >
            <ContentCopyIcon />
          </IconButton>
        </Box>

        {copySuccess && (
          <Typography variant="body2" color="success.main" align="center" gutterBottom>
            Link copied to clipboard!
          </Typography>
        )}

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!stripeConnected && (
              <Box display="flex" justifyContent="center" sx={{ mb: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConnectStripe}
                >
                  Connect Stripe Account
                </Button>
              </Box>
            )}

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Name</strong></TableCell>
                    <TableCell><strong>Email</strong></TableCell>
                    <TableCell><strong>Paid</strong></TableCell>
                    <TableCell><strong>Address</strong></TableCell>
                    <TableCell><strong>Phone</strong></TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((customer, index) => (
                    <TableRow key={index}>
                      <TableCell>{customer.name}</TableCell>
                      <TableCell>{customer.email}</TableCell>
                      <TableCell>
                        {customer.paid ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>{customer.address}</TableCell>
                      <TableCell>{customer.phone}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </Main>
  );
};

export default AdminDashboard;
