import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'Accelerate Business Growth',
    subtitle:
        'Our tailored consulting strategies help blue-collar businesses like yours grow and scale efficiently, while maximizing profits.',
  },
  {
    title: 'On-Demand Services for Small Businesses',
    subtitle:
        'We provide customized solutions for service-based businesses, focusing on lead generation, customer retention, and revenue optimization.',
  },
  {
    title: '24/7 Support for Your Business Needs',
    subtitle:
        'Our expert team is available to assist you anytime, ensuring your small business stays on track for growth and success.',
  },
];

const Plans = () => {
  return (
      <Box>
        <Box marginBottom={4}>
          <Typography
              variant="h4"
              align={'center'}
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
          >
            Business-Grade Solutions for Every Blue-Collar Business
          </Typography>
          <Typography variant="h6" align={'center'} color={'text.secondary'}>
            Whether you're in landscaping, plumbing, or any other service-based industry, our consulting services are tailored to help you grow and succeed.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Box
                    height={1}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                >
                  <Typography variant={'h6'} fontWeight={600} gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography color="text.secondary">{item.subtitle}</Typography>
                  <Box flexGrow={1} marginBottom={2} />
                </Box>
              </Grid>
          ))}
        </Grid>
      </Box>
  );
};

export default Plans;